
import axios from "@/plugins/axios.js"

const actions = {
  fetchOrders({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/orders")
        .then((response) => {
          if(response.data.success){
            commit('SET_ORDERS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchOrder({ commit }, orderId) {
    return new Promise((resolve, reject) => {
      axios.get(`/orders/${orderId}/show`)
        .then((response) => {
          if(response.data.success){
              commit('SET_ORDER', response.data.data)
           }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchDeliveries({ commit }, orderId) {
    return new Promise((resolve, reject) => {
      axios.get(`/order-deliveries/${orderId}/show`)
        .then((response) => {
          if(response.data.success){
              commit('SET_ORDER_DELIVERIES', response.data.data)
           }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchMessages({ commit }, orderId) {
    return new Promise((resolve, reject) => {
      axios.get(`/order-messages/${orderId}/show`)
        .then((response) => {
          if(response.data.success){
            commit('SET_ORDER_MESSAGES', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}


const getters  = {
	totalOrders: (state) => state.orders.length,
}


const mutations  = {
  SET_ORDERS(state, orders) {
    state.orders = orders
  },
  SET_ORDER(state, order){
  	state.order = order
    if(state.orders.length){
      const dataIndex = state.orders.findIndex((o) => o.id == order.id);
      Object.assign(state.orders[dataIndex], order);
    }
  },

  /**************** THIS IS FOR ACTIVITIES********************/
  SET_ACTIVITIES(state, activities){
    state.order.activities = [...activities]
  },
  ADD_ACTIVITY(state, activity){
    state.order.activities.push(activity)
  },
  UPDATE_ACTIVITY(state, activity){
    const dataIndex = state.order.activities.findIndex((a) => a.id == activity.id);
    Object.assign(state.order.activities[dataIndex], activity);
  },
  DELETE_ACTIVITY(state, activityId){
    const itemIndex = state.order.activities.findIndex((item) => item.id == activityId)
      state.order.activities.splice(itemIndex, 1)
  },
}


const state = {
  orders: [],
  order: {},
}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

