
let searchAndPinList = {
  pages: {
    key: "title",
    data: [
      {title: "Dashboard",                  url: "/dashboard",                     icon: "HomeIcon",           is_bookmarked: true},
      {title: "Pending Orders",             url: "/orders?status=pending",                 icon: "ShoppingBagIcon",           is_bookmarked: true},
      {title: "Delivered Orders",           url: "/orders?status=delivered",               icon: "SendIcon",           is_bookmarked: true},
      {title: "Completed Orders",           url: "/orders?status=completed",               icon: "ActivityIcon",           is_bookmarked: true},

    ]
  },
}

export default searchAndPinList
